import PropTypes from "prop-types";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import useQueryParams from "src/hooks/useQueryParams";
import { PATH_DASHBOARD } from "src/routes/paths";

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard() {
  const { queryObject } = useQueryParams();
  const { redirect } = queryObject || {};
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    if (redirect === "kyc") {
      return <Navigate to="/user/profile/settings#kyc" />;
      return;
    }
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  return (
    <>
      <Outlet />
    </>
  );
}
